import React from "react"
import { graphql } from "gatsby"
import AirvoicePrivacyComponent from "../components/airvoice-privacy/airvoice-privacy.components"

const AirvoicePrivacyPage = () => {
  return (
      <AirvoicePrivacyComponent />
  )
}

export default AirvoicePrivacyPage
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
