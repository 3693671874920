import React from 'react'
import './airvoice-privacy.components.less'

const AirvoicePrivacyComponent = () => {
    return (
        <div>
            <div className="container">
                <div className="wrapper_block__block airvoice-content">
                    <h1>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</h1>
                    <p> Настоящая Политика конфиденциальности персональной информации (далее —
                        Политика) действует в отношении всей информации, которую Общество с ограниченной
                        ответственностью «СитиЭйр» (ООО «СитиЭйр» ИНН 7731400381, адрес места нахождения:
                        121205, Город Москва, Территория Сколково инновационного центра, бульвар Большой, дом
                        42, строение 1, эт./пом. 2/963) (далее – Оператор) может получить от Пользователя сети
                        Интернет во время использования им Сервисов, размещённых в сети Интернет по адресу
                        &nbsp;<a href="https://airvoice.io">airvoice.io</a> и приложение AirVoice далее — Сервис).
                        Иcпользование Сервиса Оператора означает
                        безоговорочное согласие Пользователя с настоящей Политикой и указанными в ней условиями
                        обработки его персональной информации; в случае несогласия с этими условиями
                        Пользователь должен воздержаться от использования Сервиса и отказаться от присоединения
                        к данной Политике и Согласию на обработку персональных данных
                    </p>
                    <h2>1. ПЕРСОНАЛЬНАЯ ИНФОРМАЦИЯ ПОЛЬЗОВАТЕЛЕЙ, КОТОРУЮ ПОЛУЧАЕТ И ОБРАБАТЫВАЕТ ООО «СИТИЭЙР»</h2>
                    <p class="sub">1.1. В рамках настоящей Политики под «персональной информацией пользователя»
                        понимаются:</p>
                    <p class="sub1">1.1.1. Персональная информация, которую Пользователь предоставляет о себе
                        самостоятельно при регистрации (создании учётной записи) или в процессе
                        использования Сервиса, включая персональные данные Пользователя.</p>
                    <p class="sub1">1.1.2. Персональная информация, которую Пользователь предоставляет о третьих
                        лицах в процессе использования Сервиса, включая персональные данные третьих
                        лиц.</p>
                    <p class="sub1">1.1.3. Данные, которые автоматически передаются Сервису в процессе его
                        использования с помощью установленного на устройстве Пользователя
                        программного обеспечения, в том числе IP-адрес, информация из cookie,
                        информация о браузере пользователя (или иной программе, с помощью которой
                        осуществляется доступ к Сервису), время доступа, адрес запрашиваемой
                        страницы.</p>
                    <p class="sub1">1.1.4. Иная информация о Пользователе, сбор и/или предоставление которой
                        определено в регулирующих документах по Сервису (при наличии таких
                        документов).</p>
                    <p class="sub">1.2. Настоящая Политика применима только к Сервису. Оператор не контролирует и не
                        несет ответственность за сайты третьих лиц, на которые Пользователь может перейти
                        по ссылкам, доступным в Сервисе. На таких сайтах у Пользователя может собираться
                        или запрашиваться иная персональная информация, а также могут совершаться иные
                        действия.</p>
                    <p class="sub">1.3. Оператор не проверяет достоверность персональной информации, предоставляемой
                        Пользователями, и не осуществляет контроль их дееспособности. Однако Оператор
                        исходит из того, что Пользователь предоставляет достоверную и достаточную
                        персональную информацию, необходимую Сервису, и поддерживает эту информацию
                        в актуальном состоянии, обладает полной дееспособностью.</p>

                    <h2>2. ЦЕЛИ СБОРА И ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЕЙ</h2>
                    <p class="sub">2.1. Оператор собирает, обрабатывает и хранит только те персональные данные, которые
                        необходимы для предоставления Сервиса и оказания услуг (исполнения соглашений с
                        Пользователем), в частности для обработки заказов Пользователя, приобретения
                        Пользователем товаров Оператора, исполнения Оператором принятых на себя
                        обязательств по договору с Пользователем. Оператор не собирает, не обрабатывает и
                        не хранит персональные данные, которые прямо не связаны или не требуются для
                        выполнения целей обработки персональных данных, упомянутых в настоящей
                        Политике и/или в соответствующих уведомлениях, направленных Пользователю.</p>
                    <p class="sub">2.2. Персональную информацию пользователя Оператор может использовать в следующих
                        целях:</p>
                    <p class="sub1">2.2.1. Идентификация стороны в рамках соглашений и договорных отношений с
                        Оператором;</p>
                    <p class="sub1">2.2.2. Предоставление пользователю персонализированного Сервиса;</p>
                    <p class="sub1">2.2.3. Связь с пользователем, в том числе направление уведомлений, запросов и
                        информации, касающихся использования Сервиса, оказания услуг, а также
                        обработка запросов и заявок от Пользователя;</p>
                    <p class="sub1">2.2.4. Улучшение качества Сервиса, удобства использования, разработка новых
                        сервисов и услуг;</p>
                    <p class="sub1">2.2.5. Проведение статистических и иных исследований на основе обезличенных
                        данных;</p>
                    <p class="sub1">2.2.6. Предоставление информации рекламного характера, ограниченной
                        законодательством и отраслевыми стандартами стран, в которых
                        распространяются Приложения.</p>

                    <h2>3. УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ И
                        ЕЁ ПЕРЕДАЧИ ТРЕТЬИМ ЛИЦАМ</h2>
                    <p class="sub">3.1. Оператор хранит персональную информацию Пользователей в соответствии с
                           внутренним регламентом работы Сервиса.</p>
                    <p class="sub">3.2. В отношении Персональной информации Пользователя сохраняется ее
                           конфиденциальность, кроме случаев добровольного предоставления Пользователем
                           информации о себе для общего доступа неограниченному кругу лиц.</p>
                    <p class="sub">3.3. Оператор вправе передать персональную информацию пользователя третьим лицам в
                           следующих случаях:</p>
                    <p class="sub1">3.3.1. Пользователь выразил свое согласие на передачу данных партнерам Оператора,
                           если такая передача необходима в рамках использования Пользователем
                           определенного Сервиса либо для оказания услуги Пользователю;</p>
                    <p class="sub1">3.3.2.Передача предусмотрена российским или иным применимым законодательством
                           в рамках установленной законодательством процедуры;</p>
                    <p class="sub1">3.3.3. Такая передача происходит в рамках реорганизации, продажи или иной передачи
                        деятельности (полностью или в части) Оператора, при этом к приобретателю
                        переходят все обязательства по соблюдению условий настоящей Политики
                        применительно к полученной им персональной информации Пользователей;</p>
                    <p class="sub1">3.3.4. В целях обеспечения возможности защиты прав и законных интересов
                           Оператора или третьих лиц в случаях, когда пользователь нарушает условия
                           Пользовательского (или иного аналогичного по своему предмету) соглашения.</p>
                    <p class="sub">3.4. При обработке персональных данных пользователей Оператор руководствуется
                           Федеральным законом РФ No 152-ФЗ от 27.06.2006 «О персональных данных».</p>
                    <p class="sub">3.5. Обработка и хранение обезличенных персональных данных осуществляется на
                        территории Российской федерации на серверах ООО "Яндекс.Облако" (юридический
                        адрес: 119021, город Москва, ул. Льва Толстого, д. 16, помещ. 528, ИНН 7704458262),
                        используемых Оператором на законных основаниях.</p>
                    <h2>4. МЕРЫ, ПРИМЕНЯЕМЫЕ ДЛЯ ЗАЩИТЫ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЕЙ</h2>

                    <p class="sub">4.1. Оператор принимает необходимые и достаточные организационные и технические
                           меры для защиты персональной информации пользователя от неправомерного или
                           случайного доступа, уничтожения, изменения, блокирования, копирования,
                           распространения, а также от иных неправомерных действий с ней третьих лиц.
                           Оператором принимаются следующие организационно-технические меры:</p>
                    <ul>
                        <li>назначение из числа сотрудников Оператора лиц, ответственных за организацию
                           обработки и защиты персональных данных;</li>
                        <li>ограничение состава лиц, имеющих доступ к персональным данным, их
                           ознакомление с требованиями федерального законодательства и нормативных
                           документов Оператора по обработке и защите персональных данных;</li>
                        <li>организация
                           учета, хранения и обращения носителей информации;</li>
                        <li>определение угроз безопасности персональных данных при их обработке,
                            формирование на их основе моделей угроз;</li>
                        <li>разработка на основе модели угроз системы защиты персональных данных;</li>
                        <li>проверка готовности и эффективности использования средств защиты информации;</li>
                        <li>разграничение доступа пользователей к информационным ресурсам и
                            программно-аппаратным средствам обработки информации;</li>
                        <li>организация пропускного режима на территорию Компании, охраны помещений с
                            техническими средствами обработки персональных данных.</li>
                        <li>шифрование и безопасность: технические и организационные меры безопасности, в
                            том числе инструменты шифрования и проверки подлинности, для надежного
                            хранения персональных данных. Оператор защищает персональные данные
                            безопасными сетями, и к ним имеет доступ ограниченного количество лиц,
                            обладающих специальными правами доступа к таким системам. Оператор
                            осуществляет резервное копирование персональных данных.</li>
                        <li>персональные данные, которые собирает или создает (обрабатывает) в рамках
                            функционирования сайтов и приложений Оператор, хранятся в России.
                            Персональные данные хранятся в течение всего времени, необходимого для
                            достижения целей, в соответствии с которыми они собраны, кроме случаев, когда
                            законом установлено иное.</li>
                    </ul>
                    <h2>5. ИЗМЕНЕНИЕ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ. ПРИМЕНИМОЕ ЗАКОНОДАТЕЛЬСТВО</h2>
                    <p class="sub">5.1. Оператор имеет право вносить изменения в настоящую Политику
                        конфиденциальности. При внесении изменений в актуальной редакции указывается
                        дата последнего обновления. Новая редакция Политики вступает в силу с момента ее
                        размещения, если иное не предусмотрено новой редакцией Политики.</p>
                    <p class="sub">5.2. К настоящей Политике и отношениям между пользователем и Оператором,
                        возникающим в связи с применением Политики конфиденциальности, подлежит
                        применению право Российской Федерации.</p>

                    <p>Эта политика действует с 27 октября 2022 года.</p>
                    <h2>Свяжитесь с нами</h2>

                    <p>Если у вас есть какие-либо вопросы или предложения по нашей Политике конфиденциальности,
                        не стесняйтесь обращаться к нам по адресу <a className="contact_main__a_mail" href="mailto:developer@cityair.ru">developer@cityair.ru</a>.
                    </p>
                </div>
            </div>
        </div>
    )
}

AirvoicePrivacyComponent.propTypes = {}

AirvoicePrivacyComponent.defaultProps = {}

export default AirvoicePrivacyComponent
